import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// components

import Sidebar from '../../components/common/Sidebar/Sidebar-WMS/Sidebar-History';
import FooterAdmin from '../../components/common/Footers/FooterAdmin';
import Spinners from '../../components/Loading/SpinnerLoading';
import BottomNavigation from '../../components/common/Navbars/BottomNav/HistoryBottomNavbar';
import * as actions from '../../redux/actions';

// views

import HistoryDashboard from '../../views/wms/History/HistoryDashboard/HistoryDashboard';
import PerCaptiaDashboard from '../../views/wms/History/PerCapitaDashboard/PerCapitaDashboard';
import DepartmentPerCaptiaDashboard from '../../views/wms/History/PerCapitaDashboard/DepartmentPercapitaDashboard';
import HistoryTable from '../../views/wms/History/HistoryDashboard/HistoryTable';
import DepartmentHistoryTable from '../../views/wms/History/HistoryDashboard/DepartmentHistoryTable';
import DepartmentHistory from '../../views/wms/History/HistoryDepartment/HistoryDepartmentOverview';
import EachDepartmentHistory from '../../views/wms/History/HistoryDepartment/HistoryEachDepart';

// Enumeration
import { ROLES } from '../../config/UserRoles';

export default function HistoryAndReport() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const me = useSelector((state) => state.me);

  // ข้อมูลสำหรับ authorize จาก  local_storage
  // eslint-disable-next-line no-undef
  const storageRemember = localStorage.getItem('me');

  useEffect(() => {
    console.log('WMS-Dashboard Module is Active');
    dispatch(actions.meGet(storageRemember));
    dispatch(actions.informationGet());
    dispatch(actions.departmentAll());
    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me) {
      if (
        me.employee_role === ROLES.ADMIN ||
        me.employee_role === ROLES.SUPER_USER
      ) {
        console.log('User is Allow');
        setIsLoading(true);
      } else {
        history.push('/');
      }
    }

    return () => {};
  }, [me]);

  if (isLoading === true) {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-gray-200">
          <div className=" py-0 md:px-10 mx-auto w-full min-h-screen ">
            <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg print-area rounded  min-h-screen">
              <div className="rounded-t mb-0 px-4 pt-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-gray-500 text-xs font-semibold hidden md:block">
                      รายงานและประวัติ
                    </h6>
                  </div>
                </div>
              </div>
              <div className="px-2 md:px-4">
                <Switch>
                  {/*ตารางการทำงานของแต่ละแผนก */}
                  <Route
                    path="/wms/history/table/:id"
                    exact
                    component={DepartmentHistoryTable}
                  />

                  {/*สถานะการทำงานของแต่ละแผนก */}
                  <Route
                    path="/wms/history/department/:id"
                    exact
                    component={EachDepartmentHistory}
                  />

                  {/*สถานะการทำงานของแต่ละคน ตามแผนก  */}
                  <Route
                    path="/wms/history/percapita/:id"
                    exact
                    component={DepartmentPerCaptiaDashboard}
                  />

                  {/*ภาพรวมประวัติ  */}
                  <Route
                    path="/wms/history"
                    exact
                    component={HistoryDashboard}
                  />

                  {me?.employee_role === ROLES.SUPER_USER && (
                    <>
                      {/*ตารางการทำงาน  */}
                      <Route
                        path="/wms/history/table"
                        exact
                        component={HistoryTable}
                      />
                      {/*สถิติในแผนก  */}
                      <Route
                        path="/wms/history/department"
                        exact
                        component={DepartmentHistory}
                      />
                      {/*สถานะการทำงานของแต่ละคน  */}
                      <Route
                        path="/wms/history/percapita"
                        exact
                        component={PerCaptiaDashboard}
                      />
                    </>
                  )}

                  <Redirect from="/wms/history/*" to="/wms/history/" />
                </Switch>
              </div>
              <div />
            </div>
          </div>
          <BottomNavigation />
          <FooterAdmin />
        </div>
      </>
    );
  }

  return <Spinners />;
}
