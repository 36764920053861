import axios from 'axios';
import {
  COMMONWORK_GET,
  COMMONWORK_ALL,
  COMMONWORK_BY_DEPARTMENT,
  COMMONWORK_POST,
  COMMONWORK_DELETE,
  COMMONWORK_EDIT,
} from '../types';

export const commonWorkAll = () => async (dispatch) => {
  await axios.get(`${process.env.REACT_APP_API_URL}/commonwork`).then((res) => {
    console.log('Request Server to Get All commonworks');
    dispatch({ type: COMMONWORK_ALL, payload: res.data });
  });
};

export const commonWorkGet = (payload) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/commonwork/${payload}`)
    .then((res) => {
      console.log('Request Server to Get a commonwork');
      if (res.data) {
        dispatch({ type: COMMONWORK_GET, payload: res.data });
      } else {
        dispatch({ type: COMMONWORK_GET, payload: null });
      }
    });
};

export const commonWorkAllByDepartment = (payload,{start = "",end=""}={}) => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/commonwork/department/${payload}&start=${start}&end=${end}`)
    .then((res) => {
      console.log('Request Server to All commonwork By Department');
      if (res.data) {
        dispatch({ type: COMMONWORK_BY_DEPARTMENT, payload: res.data });
      }
    });
};

export const commonWorkPost = (payload) => async (dispatch) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/commonwork/`, payload)
    .then((res) => {
      console.log('Request Server to Post a commonwork');
      dispatch({ type: COMMONWORK_POST, payload: res.data });
    });
};

export const commonWorkEdit = (id, payload) => async (dispatch) => {
  await axios
    .put(`${process.env.REACT_APP_API_URL}/commonwork/${id}`, payload)
    .then((res) => {
      console.log('Request Server to Edit a commonwork');
      dispatch({ type: COMMONWORK_EDIT, payload: res.data });
    });
};

export const commonWorkDelete = (payload) => async (dispatch) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/commonwork/${payload}`)
    .then((res) => {
      console.log('Request Server to Delete commonwork by ID');
      if (res.data) {
        dispatch({ type: COMMONWORK_DELETE, payload: null });
      }
    });
};
