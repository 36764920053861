import React, { useState, useEffect } from 'react';
import { Select, Spinner } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { TIME_DURATION, OtherData } from '../../config/WordUtil';
import * as actions from '../../redux/actions';
import CardEachTaskAnalysis from '../common/Cards/Task/CardEachTaskAnalysis';

export default function TaskStatistic({ allTask, departmentId, selectedRange }) {
  const [selectedTime, setSelectedTime] = useState(TIME_DURATION.THIS_MONTH);
  const [filteredTask, setFilteredTask] = useState();
  const [filteredTaskOnCommonWork, setFilteredTaskOnCommonWork] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const commonWork = useSelector((state) => state.commonWork);

  useEffect(() => {
    dispatch(actions.commonWorkAllByDepartment(departmentId,{start:moment(selectedRange?.[0]).format('YYYY-MM-DD'), end:moment(selectedRange?.[1]).format('YYYY-MM-DD')})).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, [selectedRange]);

  useEffect(() => {
    let tempFilteredTask;
    if (selectedTime === TIME_DURATION.THIS_MONTH) {
      tempFilteredTask = _.filter(allTask, (eachTask) =>
        moment(eachTask.task_issue_date).isSameOrAfter(
          moment().subtract(1, 'months'),
        ),
      );
    } else if (selectedTime === TIME_DURATION.THIS_QUATER) {
      tempFilteredTask = _.filter(allTask, (eachTask) =>
        moment(eachTask.task_issue_date).isSameOrAfter(
          moment().subtract(3, 'months'),
        ),
      );
    } else if (selectedTime === TIME_DURATION.THIS_YEAR) {
      tempFilteredTask = _.filter(allTask, (eachTask) =>
        moment(eachTask.task_issue_date).isSameOrAfter(
          moment().subtract(1, 'years'),
        ),
      );
    } else if (selectedTime === TIME_DURATION.ALL) {
      tempFilteredTask = allTask;
    } else {
      tempFilteredTask = _.filter(allTask, (eachTask) =>
        moment(eachTask.task_issue_date).isSameOrAfter(
          moment().subtract(1, 'months'),
        ),
      );
    }
    setFilteredTask(tempFilteredTask);
    console.log('Filtered Task', tempFilteredTask);

    const tempFilteredTaskOnCommonWork = _.groupBy(
      tempFilteredTask,
      'task_commonwork_id',
    );
    setFilteredTaskOnCommonWork(tempFilteredTaskOnCommonWork);
    console.log('Filtered Task on Common Work', tempFilteredTaskOnCommonWork);
    return () => {};
  }, [selectedTime]);

  if (isLoading)
    return (
      <div>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
          <div className="px-\  p-2">
            <div className="m-2 md:w-1/3">
              <p>ช่วงเวลา</p>
              <Select
                placeholder="เลือกช่วงเวลา"
                value={selectedTime}
                onChange={(event) => setSelectedTime(event.target.value)}
              >
                <option value={TIME_DURATION.THIS_MONTH}>เดือนที่ผ่านมา</option>
                <option value={TIME_DURATION.THIS_QUATER}>
                  3 เดือนที่ผ่านมา
                </option>
                <option value={TIME_DURATION.THIS_YEAR}>ปีที่ผ่านมา</option>
                <option value={TIME_DURATION.ALL}>ทั่งหมด</option>
              </Select>
            </div>
          </div>
        </div>

        {_.map(commonWork, (eachCommonWork, index) => {
          if (filteredTaskOnCommonWork[eachCommonWork.work_id]) {
            return (
              <CardEachTaskAnalysis
                taskArray={filteredTaskOnCommonWork[eachCommonWork.work_id]}
                commonWorkDatas={eachCommonWork}
                allTaskSize={_.size(filteredTask)}
                key={index}
              />
            );
          } else return <div key={index} />;
        })}
        {filteredTaskOnCommonWork[OtherData.id] && (
          <CardEachTaskAnalysis
            commonWorkDatas={null}
            allTaskSize={_.size(filteredTask)}
            taskArray={_.union(
              filteredTaskOnCommonWork[OtherData.id],
              filteredTaskOnCommonWork[null]
                ? filteredTaskOnCommonWork[null]
                : [],
            )}
          />
        )}
      </div>
    );
  else {
    return <Spinner />;
  }
}
