import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import OverviewChart from './Components/AllWorkGraph';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
export default function HistoryDashboard() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const task = useSelector((state) => state.task);

  const department = useSelector((state) => state.department);
  const [selectedRange, setSelectedRange] = useState([
    moment().subtract(1, 'months').toDate(),
    moment().toDate(),
  ]);
  console.log("selectedRange[0]", selectedRange[0]);
  console.log("selectedRange[1]", selectedRange[1]);
 

  useEffect(() => {
    setIsLoading(false)
    dispatch(actions.departmentAll()).then(() => {
      dispatch(actions.taskAll(1, 10000, {start:moment(selectedRange?.[0]).format('YYYY-MM-DD'),end:moment(selectedRange?.[1]).format('YYYY-MM-DD')})).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, [selectedRange]);

  // UI Rendering
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1">
          รายะเอียดงานทั้งหมด
        </h2>

        <div className="py-4 md:py-6">
          <Link to="/">
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div >
        <div className='px-4 text-lg font-semibold'>ข้อมูลจากวันที่ {moment(selectedRange?.[0]).format('D MMM YYYY')} จนถึง {moment(selectedRange?.[1]).format('D MMM YYYY')}

        </div>
          <div className=" justify-end px-4 lg:w-3/12 ">
          <div className=' justify-end py-4'>
          <p>เลือกวันและเวลา</p>
           <DateRangePicker
                value={selectedRange}
                onChange={setSelectedRange}
                clearIcon={null}
                 />
            </div>        
          </div>            
          {isLoading ? <OverviewChart allDepartment={department} allTask={task?.arr} /> : <Spinners/>}
        </div>
      </div>)
   
}
